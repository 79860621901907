<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .custom-switch {
    margin-top: unset;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import WorkorderHeader from "./workorder-header";
  import {
    workOrderSelfListPage
  } from "@/api/admin/workOrder/apply";
  import YzSearch from "@/components/form/yzSearch.vue";
  import {
    getDicts
  } from "@/api/common/dict";
  import {
    deleteWorkOrderApply,
    applyWorkOrderApply
  } from "@/api/admin/workOrder/apply";

  /**
   * 工单列表
   */
  export default {
    components: {
      Layout,
      WorkorderHeader,
      YzSearch,
    },
    data() {
      return {
        items: [{
            text: "工单管理",
            href: "/admin"
          },
          {
            text: "所有工单",
            active: true
          }
        ],
        defaultvalue: 1,
        currentpage: 1,
        checked: true,
        pageData: {
          sid: ""
        },
        tableList: [],
        search: []
      };
    },
    methods: {
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      getList() {
        this.pageData.applyStatus = this.$route.params.id
        workOrderSelfListPage(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      deleteItem(id) {
        this.$confirm(`此操作将永久删除该【${id}】的数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteWorkOrderApply(id).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          })
        });
      },
      applyOrder(id) {
        this.$confirm("是否受理该工单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          applyWorkOrderApply(id).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "受理成功!",
              });
              this.getList();
            }
          })
        });
      }
    },
    mounted() {
      this.getList()
      getDicts("gddl", "gdxl").then(res => {
        this.search = [{
            title: "大类",
            bound: ["sid"],
            type: "select",
            dict: JSON.parse(JSON.stringify(res.gddl)),
          },
          {
            title: "小类",
            bound: ["tid"],
            type: "select",
            filter: "sid",
            dict: JSON.parse(JSON.stringify(res.gdxl)),
          },
          {
            title: "状态",
            bound: ["applyStatus"],
            type: "select",
            dict: [{
                name: "已受理",
                value: "0"
              },
              {
                name: "已完结",
                value: "1"
              },
              {
                name: "正在处理",
                value: "2"
              },
              {
                name: "已回复",
                value: "3"
              },
              {
                name: "待用户反馈",
                value: "4"
              },
            ],
          },
          {
            title: "优先级",
            bound: ["applyLevel"],
            type: "select",
            dict: [{
                name: "普通",
                value: "normal"
              },
              {
                name: "重要",
                value: "important"
              },
            ],
          },
          {
            title: "工单简述",
            bound: "keyword",
            type: "input",
          },
        ]
      })
    }
  };
</script>

<template>
  <Layout>
    <WorkorderHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">

        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <a href="/workorder">
                  <b-button variant="outline-info" class="flexList mr-2 condition"><i
                      class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>发起工单
                  </b-button>
                </a>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%;">序号</th>
                    <th style="width: 8%;">工单类别</th>
                    <th style="width: 8%;">工单类型</th>
                    <th style="width: 8%;">工单号</th>
                    <th style="width: 5%;">申请人</th>
                    <th style="width: 8%;">当前受理人</th>
                    <th style="width: 6%;">优先级</th>
                    <th style="width: 6%;">是否结束</th>
                    <th>工单简述</th>
                    <th style="width: 6%">发起时间</th>
                    <th style="width: 6%">截至时间</th>
                    <th style="width: 6%">受理</th>
                    <th style="width: 3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td> {{ (pageData.pageNum - 1) * pageData.pageSize +  index + 1 }}</td>
                    <td>{{ obj.sortName }}</td>
                    <td>{{ obj.typeName }}</td>
                    <td>{{ obj.wid }}</td>
                    <td class="text-info">{{ obj.applyXm }}</td>
                    <td>{{ obj.dealXm ? obj.dealXm : '等待受理' }}</td>
                    <td> {{ obj.applyLevel == 'normal' ? '普通' : '重要' }}</td>
                    <td :class="obj.applyStatus!=1?'text-info':''"> {{ obj.applyStatus == 1 ? '已结束' : '未结束' }}</td>
                    <td> {{ obj.applyTitle }}</td>
                    <td> {{ formatDate(obj.createTime) }}</td>
                    <td> {{ formatDate(obj.dealEndTime) }}</td>
                    <td>
                      <a :href="'/workOrderHandle?wid='+obj.wid" class="blue-font"
                        v-if="obj.dealGh == userInfo.yhzh || obj.applyGh == userInfo.yhzh" target="_blank">
                        前往处理
                      </a>
                    </td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.wid)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
  </Layout>
</template>
