import { getRequest, postRequest, putRequest, deleteRequest, patchRequest } from "@/libs/axios";

let base = "/yethan/workOrder"
export const workOrderListPage = (params)=>{
    return getRequest(`${base}/listPage`, params)
}
export const workOrderSelfListPage = (params)=>{
    return getRequest(`${base}/self/listPage`, params)
}
export const workOrderAllListPage = (params)=>{
    return getRequest(`${base}/all/listPage`, params)
}
export const addWorkOrderApply = (params)=>{
    return postRequest(`${base}`, params)
}
export const deleteWorkOrderApply = (wid)=>{
    return deleteRequest(`${base}/${wid}`)
}
export const applyWorkOrderApply = (wid)=>{
    return patchRequest(`${base}/${wid}/apply`)
}
export const workOrderApply = (wid)=>{
    return getRequest(`${base}/${wid}`)
}
export const workOrderApplyClose = (wid)=>{
    return patchRequest(`${base}/${wid}/close`)
}
export const workOrderApplyEvaluate = (wid, params)=>{
    return patchRequest(`${base}/${wid}/evaluate`, params)
}
export const workOrderApplyShare = (wid, params)=>{
    return patchRequest(`${base}/${wid}/share`,  params)
}