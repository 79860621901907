<style type="text/css">
@import "~@/assets/css/table.css";
</style>
<script>
export default {
  components: {},
  data() {
    return {

      isActive: 1,
      pathArr: [
        {
          index: 8,
          url: "/workorderList/1",
          name: "已关闭的工单",
        },
        {
          index: 9,
          url: "/workorderList",
          name: "我创建的工单",
        }
      ]
    }
  },
  mounted() {
    this.isActive = window.location.pathname

  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box flexList justify-content-between">
        <div class="flexList col-4 p-0">
          <a :href="obj.url" class="col-sm-3 p-0 mr-3" v-for="obj in pathArr" :key="obj.index">
            <div class="base-header-box " :class="{active:isActive==obj.url}">{{obj.name}}</div>
          </a>
        </div>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
